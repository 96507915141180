import React from 'react';

export default function overlay() {
  return (
    <div
      style={{
        position: `absolute`,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: `rgba(0,0,0,0.4)`,
        zIndex: 1,
      }}
    />
  );
}
